import { TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import { UpdateUser, UserProps } from "../../../../api/UserApi/User";
import { Edit, UserLight } from "../../../../assets";
import { Colors } from "../../../../utils/colors";
import ButtonStyled from "../../../Button";
import DropdownStyled from "../../../Dropdown";
import TableStyled from "../../../Table";
import AccountModal from "../AccountModal/AccountModal";
import { FilterType } from "../AgentsManagementScreen";

import { NameWrapper } from "./ManagementTable.styled";

export interface DataType {
  key: React.Key;
  natural_operator_id: number;
  name: JSX.Element;
  dt_last_login: string;
  is_active: JSX.Element;
}

export interface DataTableType {
  key: React.Key;
  natural_operator_id: number;
  name: string;
  dt_last_login: string;
  is_active: boolean;
}

type TableStyledProps = {
  data: UserProps[];
  onClick?: () => void;
  setOffset?: (val: number) => void;
  offset?: number;
  limit?: number;
  filter?: Dispatch<SetStateAction<FilterType>>;
  filterValues: any;
  onUserUpdate: (userUpdate: UpdateUser, id: string) => void;
  total: number;
};

const ManagementTable: FC<TableStyledProps> = ({
  data,
  setOffset,
  offset,
  limit = 10,
  filter,
  onUserUpdate,
  total,
}) => {
  const navigate = useNavigate();

  const columns: ColumnsType<DataTableType> = [
    {
      title: "Agent ID",
      dataIndex: "natural_operator_id",
      sorter: {},
      render: (arr) => (
        <a
          style={{ color: `${Colors.TITLE_SILVER_COLOR}` }}
          onClick={() => navigate(`user-profile/${arr[1]}`)}
        >
          {arr[0]}
        </a>
      ),
    },
    {
      title: "Agent Full Name",
      dataIndex: "name",
      sorter: {},
      render: (name) => (
        <NameWrapper>
          <div>
            <UserLight />
          </div>
          <span>{name}</span>
        </NameWrapper>
      ),
    },
    {
      title: "Last login Time",
      dataIndex: "dt_last_login",
      sorter: {},
    },
    {
      title: "Status",
      dataIndex: "is_active",
      align: "right",
      sorter: {},
      render: (userData: UserProps) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <DropdownStyled
            style={{ maxWidth: "max-content" }}
            right="12px"
            fontSize={12}
            fontWeight={700}
            onChange={(value) =>
              onUserUpdate(
                { is_active: value === "Active" },
                userData.operator_id
              )
            }
            background={
              userData.is_active ? Colors.ACTIVE_COLOR : Colors.NON_ACTIVE_COLOR
            }
            color={
              userData.is_active ? Colors.TITLE_ACTIVE : Colors.TITLE_NON_ACTIVE
            }
            bordered={false}
            defaultValue={userData.is_active ? "Active" : "Not Active"}
            options={[
              {
                value: "Active",
                label: "Active",
              },
              {
                value: "Not active",
                label: "Not active",
              },
            ]}
          />
        </div>
      ),
    },
    {
      title: "Settings",
      dataIndex: "settings",
      align: "right",
      render: (columnData) => (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <AccountModal
            width={500}
            title="Update Agent Account"
            handleSave={(data) => {
              const password = data.password?.length
                ? { password: data.password }
                : {};
              onUserUpdate(
                {
                  ...password,
                  role: data.role,
                  name: data.name,
                  email: data.email,
                  operator_phone: data.operator_phone,
                  natural_operator_id: data.natural_operator_id,
                },
                data.operator_id
              );
            }}
            initialData={{
              ...columnData,
              id: columnData.operator_id,
            }}
          >
            <ButtonStyled background={Colors.BLACK_COLOR} padding="1px">
              <Edit />
            </ButtonStyled>
          </AccountModal>
        </div>
      ),
    },
  ];

  const createdAt = (date: Date): string => {
    return `${date.getFullYear()}/${
      date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${date.getDate()}`;
  };

  const dataParsers = (data: UserProps[]) => {
    return data.map((columnData) => {
      const date = createdAt(new Date(columnData.dt_last_login));
      return {
        key: `${columnData.operator_id}`,
        name: columnData.name,
        natural_operator_id: [
          columnData.natural_operator_id,
          columnData.operator_id,
        ],
        dt_last_login: date,
        is_active: columnData,
        settings: columnData,
      };
    });
  };

  const onChange: TableProps<DataType>["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    if (sorter?.order && sorter?.field) {
      const field = sorter?.order
        ? sorter.order.substr(0, 3) === "asc"
          ? sorter.order.substr(0, 3)
          : sorter.order.substr(0, 4)
        : "";
      filter?.((prev) => ({ ...prev, ["sort"]: sorter.field, ["dir"]: field }));
    }
  };

  return (
    <TableStyled
      limit={limit}
      offset={offset}
      setOffset={setOffset}
      total={total}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={columns}
      dataSource={dataParsers(data)}
      onChange={onChange}
    />
  );
};

export default React.memo(ManagementTable);
