import { TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import { ClientProps, UpdateClient } from "../../../../api/ClientApi/Client";
import { Edit, UserLight } from "../../../../assets";
import { Colors } from "../../../../utils/colors";
import ButtonStyled from "../../../Button";
import TableStyled from "../../../Table";
import { FilterType } from "../../AgentsManagement/AgentsManagementScreen";
import AccountModal from "../AccountModal/AccountModal";
import DeleteModal from "../DeleteModal/DeleteModal";

import {
  AccountWrapper,
  DeleteStyled,
  DeleteWrapper,
  NameWrapper,
} from "./ClientTable.styled";

export interface DataClientType {
  key: React.Key;
  client_id: number;
  name: JSX.Element;
  dt_signature: string;
  dt_last_call: string;
  delete: JSX.Element;
}

type TableStyledProps = {
  data: ClientProps[];
  onClick?: () => void;
  setOffset?: (val: number) => void;
  offset?: number;
  limit?: number;
  filter?: Dispatch<SetStateAction<FilterType>>;
  filterValues: any;
  onUpdate: (clientUpdate: UpdateClient, id: string) => void;
  total: number;
  onDelete: (value: string) => void;
  onDeleteClient: (value: string) => void;
};
const ClientTable: FC<TableStyledProps> = ({
  data,
  setOffset,
  offset,
  limit = 10,
  filter,
  onUpdate,
  total,
  onDelete,
  onDeleteClient,
}) => {
  const navigate = useNavigate();

  const columns: ColumnsType<DataClientType> = [
    {
      title: "Client claim ID",
      dataIndex: "claim_id",
      sorter: {},
      render: (arr) => (
        <a
          style={{ color: `${Colors.TITLE_SILVER_COLOR}` }}
          onClick={() => navigate(`/client-profile/${arr[1]}`)}
        >
          {arr[0]}
        </a>
      ),
    },
    {
      title: "Clients name ",
      sorter: {},
      dataIndex: "name",
      render: (name) => (
        <>
          {name && (
            <NameWrapper>
              <div>
                <UserLight />
              </div>
              <span>{name}</span>
            </NameWrapper>
          )}
        </>
      ),
    },
    {
      title: "Voice print",
      dataIndex: "dt_signature",
      sorter: {},
      render: (arr) => (
        <>
          {arr[0] != undefined && (
            <DeleteStyled>
              <span style={{ color: `${Colors.TITLE_SILVER_COLOR}` }}>
                {arr[0]}
              </span>
              <DeleteModal
                content={`Do you want to delete voice print ${arr[1]}.`}
                width={420}
                handleSave={() => onDelete(arr[1])}
              >
                <ButtonStyled padding="2px 4px">
                  <DeleteStyled>
                    <span>Delete Voice Print</span>
                  </DeleteStyled>
                </ButtonStyled>
              </DeleteModal>
            </DeleteStyled>
          )}
        </>
      ),
    },
    {
      title: "Last Call ID",
      dataIndex: "dt_last_call",
      sorter: {},
      render: (lastCall) => <span>{lastCall}</span>,
    },
    {
      title: "Settings",
      dataIndex: "settings",
      align: "right",
      render: (columnData) => (
        <AccountWrapper>
          <AccountModal
            width={500}
            title="Update Client Account"
            handleSave={(data) => {
              onUpdate(
                {
                  name: data.name,
                  email: data.email,
                  claim_id: data.claim_id,
                  phone: data.phone,
                },
                columnData.client_id
              );
            }}
            initialData={{
              name: columnData.name,
              email: columnData.email,
              claim_id: columnData.claim_id,
              phone: columnData.phone,
            }}
          >
            <ButtonStyled background={Colors.BLACK_COLOR} padding="1px">
              <Edit />
            </ButtonStyled>
          </AccountModal>
        </AccountWrapper>
      ),
    },
    {
      title: "Delete",
      align: "center",
      dataIndex: "delete",
      render: (clientId) => (
        <DeleteWrapper>
          <DeleteModal
            content={`Do you want to delete client ${clientId}.`}
            width={420}
            handleSave={() => onDeleteClient(clientId)}
          >
            <ButtonStyled padding="2px 4px">
              <span style={{ color: `${Colors.WHITE_COLOR}` }}>
                Delete User
              </span>
            </ButtonStyled>
          </DeleteModal>
        </DeleteWrapper>
      ),
    },
  ];

  const dataParsers = (data: ClientProps[]) => {
    return data.map((columnData) => {
      return {
        key: `${columnData.client_id}`,
        name: columnData.name,
        claim_id: [columnData.claim_id, columnData.client_id],
        dt_last_call: columnData.dt_last_call,
        dt_signature: [columnData.dt_signature, columnData.client_id],
        settings: columnData,
        delete: `${columnData.client_id}`,
      };
    });
  };

  const onChange: TableProps<DataClientType>["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    if (sorter?.order && sorter?.field) {
      const field = sorter?.order
        ? sorter.order.substr(0, 3) === "asc"
          ? sorter.order.substr(0, 3)
          : sorter.order.substr(0, 4)
        : "";
      filter?.((prev) => ({ ...prev, ["sort"]: sorter.field, ["dir"]: field }));
    }
  };

  return (
    <TableStyled
      limit={limit}
      offset={offset}
      setOffset={setOffset}
      total={total}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={columns}
      dataSource={dataParsers(data)}
      onChange={onChange}
    />
  );
};

export default ClientTable;
