import React from "react";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "antd/dist/reset.css";
import CallScreen from "./components/screens/Profile/Call/CallScreen";
import ClientScreen from "./components/screens/Profile/Client/ClientScreen";
import UserScreen from "./components/screens/Profile/User/UserScreen";
import UserProvider from "./context/auth-context";
import Home from "./pages/Home";
import Login from "./pages/Login";
import "react-notifications/lib/notifications.css";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <UserProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:id" element={<Home />} />
            <Route path="/user-profile/:id" element={<UserScreen />} />
            <Route path="/client-profile/:id" element={<ClientScreen />} />
            <Route path="/call/:id" element={<CallScreen />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </UserProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
