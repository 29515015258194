import styled from "styled-components";

import { Colors } from "../../../../utils/colors";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;

  gap: 22px;
  max-height: 800px;
  height: 100%;
`;

export const Content = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.005em;
  color: ${Colors.TITLE_MUTED};
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
    background-color: white;
    color: white;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
    color: white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${Colors.SCROLL_COLOR};
  }
`;

export const Title = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  letter-spacing: -0.02em;
  color: ${Colors.SILVER_BLUR_COLOR};
`;

export const Updated = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  letter-spacing: 0.005em;

  color: ${Colors.BLACK_COLOR};
`;

export const Privacy = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CancelStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 20px;
`;
