import { Content } from "antd/lib/layout/layout";
import React, { FC, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useMutation, useQuery, useQueryClient } from "react-query";

import {
  CreateUser,
  UpdateUser,
  userApi,
  UserProps,
} from "../../../api/UserApi/User";
import { ButtonPlus } from "../../../assets";
import { useUser } from "../../../context/auth-context";
import { QueryNames } from "../../../utils/constants/QueryNames";
import { removeEmpty } from "../../../utils/helpers/removeEmpty";
import ButtonStyled from "../../Button";
import { TextStyled } from "../../Button/Button.styled";
import SearchInput from "../../Input/SearchInput";
import PanelHeader from "../../PanelHeader";

import AccountModal from "./AccountModal/AccountModal";
import {
  AddAccountWrapper,
  TableWrapper,
} from "./AgentsManagementScreen.styled";
import ManagementTable from "./ManagementTable/ManagementTable";

export type FilterType = {
  limit: number;
  offset: number;
  sort: string;
  dir: string;
  search: string;
};

const AgentsManagementScreen: FC = () => {
  const LIMIT = 10;
  const [modalData, setModalData] = useState<UserProps>({
    dt_create: "",
    dt_last_login: "",
    dt_token_expire: "",
    dt_update: "",
    id: "",
    operator_id: "",
    org_id: 0,
    role: "",
    name: "",
    email: "",
    password: "",
    natural_operator_id: "",
    is_active: true,
    operator_phone: "",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const { user } = useUser();

  const [filterValues, setFilterValues] = useState<FilterType>({
    limit: LIMIT,
    offset: 0,
    sort: "natural_operator_id",
    dir: "",
    search: "",
  });

  const makeQuery = ({
    limit,
    search,
    offset,
    sort,
    dir,
  }: FilterType): string => {
    return `?limit=${limit}&offset=${offset}&sort=${sort}${
      dir ? `&dir=${dir}` : ""
    }${search ? `&search=${search}` : ""}
    `;
  };
  const {
    data: { operators, total },
  } = useQuery({
    queryKey: [QueryNames.GET_USER, filterValues],
    queryFn: () => {
      return userApi.get(makeQuery(filterValues));
    },
    initialData: {
      operators: [],
      total: 0,
      offset: 0,
    },
  });

  const queryClient = useQueryClient();
  const createUser = useMutation(userApi.create);
  const updateUser = useMutation(userApi.update);

  const userCreate = (newUser: CreateUser) => {
    createUser.mutate(
      {
        name: newUser.name,
        email: newUser.email,
        password: newUser.password,
        natural_operator_id: newUser.natural_operator_id,
        role: newUser.role,
        operator_phone: newUser.operator_phone,
      },
      {
        onSuccess: () => {
          NotificationManager.success("New Agent successfully created");
          setIsSuccess(true);
          setModalData({
            dt_create: "",
            dt_last_login: "",
            dt_token_expire: "",
            dt_update: "",
            id: "",
            operator_id: "",
            org_id: 0,
            role: "",
            name: "",
            email: "",
            password: "",
            natural_operator_id: "",
            is_active: true,
            operator_phone: "",
          });
          queryClient.invalidateQueries([QueryNames.GET_USER]);
        },
        onError: (error: any) => {
          NotificationManager.error(
            error?.errors?.[0]
              ? `${error.errors[0].path[0]}, ${error.errors[0].message}`
              : error.message
          );
          queryClient.invalidateQueries([QueryNames.GET_USER]);
        },
      }
    );
  };

  const onUserUpdate = (userUpdate: UpdateUser, id: string) => {
    const filteredUser = removeEmpty(userUpdate);
    updateUser.mutate(
      {
        ...filteredUser,
        id: id,
      },
      {
        onSuccess: () => {
          NotificationManager.success("User successfully updated");
          queryClient.invalidateQueries([QueryNames.GET_USER]);
        },
        onError: (error: any) => {
          NotificationManager.error(
            error?.errors?.[0]
              ? `${error.errors[0].path[0]}, ${error.errors[0].message}`
              : error.message
          );
          queryClient.invalidateQueries([QueryNames.GET_USER]);
        },
      }
    );
  };

  const onCreate = async (user: CreateUser) => {
    try {
      await userCreate(user);
    } catch (error) {
      NotificationManager.error(`Error message:  ${error}`);
    }
  };

  return (
    <>
      <PanelHeader
        tabTitle="Management"
        title="Users Management"
        subTitle={`Hello ${
          user?.name?.split(" ")?.[0] || ""
        }, we show you your most recent activity`}
      />
      <AddAccountWrapper>
        <SearchInput
          onSearch={(value: string) =>
            setFilterValues((prev) => ({ ...prev, ["search"]: value }))
          }
        />
        <AccountModal
          width={500}
          isSuccess={isSuccess}
          handleSave={(data) =>
            onCreate({
              name: data.name,
              email: data.email,
              password: data.password.trim(),
              natural_operator_id: data.natural_operator_id,
              role: data.role || "operator",
              operator_phone: data.operator_phone,
            })
          }
          title="Add Agent Account"
          initialData={modalData}
        >
          <ButtonStyled>
            <>
              <ButtonPlus />
              <TextStyled>Add Agent Account</TextStyled>
            </>
          </ButtonStyled>
        </AccountModal>
      </AddAccountWrapper>
      <Content>
        <TableWrapper>
          <ManagementTable
            onUserUpdate={onUserUpdate}
            data={operators || []}
            limit={LIMIT}
            total={total}
            filter={setFilterValues}
            filterValues={filterValues}
            offset={filterValues.offset / LIMIT + 1}
            setOffset={(value) => {
              setFilterValues((prev) => ({
                ...prev,
                ["offset"]: (value - 1) * LIMIT,
              }));
            }}
          />
        </TableWrapper>
      </Content>
    </>
  );
};

export default React.memo(AgentsManagementScreen);
