import styled from "styled-components";

import { Colors } from "../../../../utils/colors";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 0 0;
  gap: 22px;
`;

export const Content = styled.div`
  max-width: 328px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.BLACK};
`;
