import React, { FC } from "react";

import { Arrow } from "../../../assets";
import PanelHeader from "../../PanelHeader";

import { Content, ContentWrapper, StyledA, Updated } from "./Privacy.styled";

const PrivacyScreen: FC = () => {
  return (
    <>
      <PanelHeader
        title="Privacy policy"
        subTitle={
          <StyledA onClick={() => history.back()} style={{ cursor: "pointer" }}>
            <Arrow />
            <div style={{ width: "5px" }}></div>
            Back
          </StyledA>
        }
      />
      <ContentWrapper>
        <Updated>Updated December 05, 2022</Updated>
        <Content>
          Dribbble Holdings Limited (the “Dribbble Group”) is comprised of
          several companies, which together provide tools to help the world’s
          designers to create, develop and promote their talents (each a
          “Service” and collectively, the “Services”). The companies within the
          Dribbble Group each act as the data controller for personal data
          processed in respect of their Services (each a “Group Company” and
          together the “Group Companies”) and referred to as “our,” “we,” or
          “us” below. Our Group Companies operate the Services, which include
          websites, software, mobile services, and applications. This page
          explains the terms by which you may use our online and/or mobile
          services, website, and software provided on or in connection with the
          Services. These terms apply to all sites to which link to these terms,
          including, but not limited to, dribbble.com, creativemarket.com,
          fontspring.com, fontsquirrel.com, and any subdomains thereof. By using
          the Services, creating an account and checking the "I agree" (or
          similar) box, or otherwise accessing or using the Services, you (1)
          agree that you have read, understood, and agree to be bound by the
          terms and conditions of these Terms of Service (these “Terms”), and
          (2) also that you have read and acknowledge the collection and use of
          your information as set forth in our privacy policy (the "Privacy
          Policy"), whether or not you are a registered user of the Services.
          Your use of certain Services or features of our Services may be
          subject to additional terms. For example, if you purchase a font or
          other Asset from Creative Market, purchase a font from Fontspring, or
          download a font from Font Squirrel, additional license terms for the
          font or Asset may apply (each, a “License”). Similarly, if you make a
          purchase through the Services, an applicable return policy may apply.
          If you sign up to be a seller or affiliate (e.g., on Shop and Partner
          terms on Creative Market or Foundry terms on Fontspring), your use of
          the Services in such a capacity may be subject to additional terms. If
          you engage in sharing content and interact with other users in our
          community (e.g., posting or commenting on content at Dribbble), your
          conduct may be subject to Community Guidelines. We may offer
          promotions or contests through the Services from time to time, and
          your participation in these may also be subject to additional terms.
          To the extent additional terms affect your use of the Service, those
          terms (including, but not limited to, the licenses and Privacy Policy
          noted above) are hereby incorporated by reference into these Terms.
          These Terms apply to all visitors, users, buyers, sellers and others
          who access the Services ("User(s)," or "you," or "your"). If you open
          an account on the Services on behalf of an organization or other
          entity, then (i) "you" includes you and that entity, and (ii) you
          represent and warrant that you are an authorized representative of the
          entity with the authority to bind the entity to these Terms, and that
          you agree to these Terms on the entity’s behalf. PLEASE READ THESE
          TERMS CAREFULLY AND BE SURE YOU UNDERSTAND IT FULLY, BECAUSE IT
          EXPLAINS AND CONTROLS YOUR LEGAL RELATIONSHIP WITH US AND YOUR RIGHTS
          RELATED TO YOUR USE OF THE SERVICE. PLEASE NOTE: SECTION 19 CONTAINS A
          BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER. IT AFFECTS YOUR
          RIGHTS ABOUT HOW TO RESOLVE ANY DISPUTE WITH US. PLEASE READ IT
          CAREFULLY. If you have any questions, please don’t hesitate to contact
          us using our Support Form Use of Our Service. The Services provides
          creative communities where Users can share and socialize around
          content, maintain portfolios, search jobs, or connect with job
          seekers. Our services also provide a marketplace where Users can buy
          and sell design items, such as fonts, brushes, patterns, and other
          digital assets ("Assets"). Some of our Service allows for a buyer
          ("Buyer") to purchase limited licenses (in accordance with the
          applicable License) to use Assets from shops opened on the Services by
          independent creators ("Shop Owners"). References to "buying" or
          "purchasing" Assets means buying or purchasing limited licenses to
          those Assets. Eligibility. You may use the Service only if you can
          form a binding contract with us, and only in compliance with these
          Terms and all applicable local, state, provincial, national, and
          international laws, rules and regulations. Any use or access to the
          Service by anyone under 13 is strictly prohibited and in violation of
          these Terms. The Service is not available to any Users previously
          removed from the Service by Dribbble Group. License Terms. As noted
          above, your use of any Assets is subject to the applicable License
          (outlined in License Terms and Font License Terms.) We and Shop Owners
          offer different license types, so it is important that you carefully
          review the rights and restrictions of the License that applies to the
          Assets before you purchase a license to such Assets. In the event of a
          conflict between the License applicable to you and these Terms, the
          License will apply to the extent of that conflict. Our Services.
          Subject to the terms and conditions of these Terms, you are hereby
          granted a non-exclusive, limited, non-transferable, freely revocable
          right to use the Services for your use only as permitted by the
          features of the Service and by these Terms. Dribbble Group reserves
          all rights not expressly granted herein in the Services and the
          Content (as defined below). Dribbble Group may revoke this right at
          any time for any reason or no reason. Your Account. Your account gives
          you access to the services and features that we may establish,
          maintain, and modify, from time to time and in our sole discretion. We
          may maintain different types of accounts for different types of Users
          (e.g., Shop Owners, Affiliates, Dribbble Pro, Dribbble Pro Business),
          which may provide access to additional or different Services or
          features of those Services. By connecting to the Services with a
          third-party service (e.g., via your Facebook or Google account), you
          give us permission to access and use your information from that
          service as permitted by that service, and to store your login
          credentials for that service. You are responsible for tracking all
          activity on your own account, and you agree to the following: To store
          all passwords and usernames securely. To notify us of any unauthorized
          use or security breach. We will not be liable for any liability,
          damage, cost, loss or expense caused by or in connection with any
          unauthorized use of your account. To never share login details or
          account access with anyone, including clients or team members, unless
          explicitly permitted by additional terms applicable to your account
          type. To accept responsibility for activity that occurs under your
          account(s). To never transfer, rent, "sell," or otherwise make your
          account available to another person.
        </Content>
      </ContentWrapper>
    </>
  );
};

export default PrivacyScreen;
