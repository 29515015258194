import { FC, useEffect, useState } from "react";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { UpdateUser, userApi } from "../../../../api/UserApi/User";
import { Arrow } from "../../../../assets";
import { Colors } from "../../../../utils/colors";
import { QueryNames } from "../../../../utils/constants/QueryNames";
import { removeEmpty } from "../../../../utils/helpers/removeEmpty";
import ButtonStyled from "../../../Button";
import { TextStyled } from "../../../Button/Button.styled";
import DropdownStyled from "../../../Dropdown";
import InputStyled from "../../../Input/Input";

import { Text, TextTitle } from "./UserScreen.styled";

const UserScreen: FC = () => {
  const queryClient = useQueryClient();
  const updateUser = useMutation(userApi.update);
  const [isEdit, setIsEdit] = useState(false);
  const { id } = useParams();

  const { data } = useQuery({
    queryFn: () => {
      return userApi.getById(id as string);
    },
  });

  const [modalData, setModalData] = useState<UpdateUser>();

  useEffect(() => {
    if (data) {
      setModalData({
        email: data.email,
        is_active: data.is_active,
        name: data.name,
        password: data.password,
        natural_operator_id: data.natural_operator_id,
        role: data.role,
      });
    }
    {
    }
  }, [data]);

  const onUserUpdate = (userUpdate: UpdateUser) => {
    const filteredUser = removeEmpty(userUpdate);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { password, ...rest } = filteredUser;
    updateUser.mutate(
      {
        ...rest,
        id: id as string,
      },
      {
        onSuccess: () => {
          NotificationManager.success("User updated");
          setIsEdit(() => false);
          queryClient.invalidateQueries([QueryNames.GET_USER]);
        },
        onError: (error: any) => {
          NotificationManager.error(
            `${JSON.stringify(error?.request.response)}`
          );
        },
      }
    );
  };
  const isAuth =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const notEditableText = (title: string, text?: string) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          borderBottom: `1px solid ${Colors.TITLE_INNER_COLOR}`,
        }}
      >
        <TextTitle>{title}</TextTitle>
        <Text>{text}</Text>
      </div>
    );
  };
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        height: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
      }}
    >
      <NotificationContainer />
      {!isAuth && <Navigate to={"/login"} replace={true} />}
      {data?.operator_id === id && modalData && (
        <div
          style={{
            marginTop: "40px",
            display: "flex",
            flexDirection: "column",
            maxWidth: "600px",
            padding: "10px",
            width: "100%",
            gap: "24px",
          }}
        >
          <a
            onClick={() => navigate("/")}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "16px 0",
              width: "100%",
              fontWeight: 700,
            }}
          >
            <Arrow></Arrow>
            <div style={{ width: "5px" }}></div>
            Back
          </a>
          {isEdit ? (
            <InputStyled
              title="Full name:"
              value={modalData.name}
              onChange={(value) => {
                setModalData((prev: any) => ({ ...prev, ["name"]: value }));
              }}
            />
          ) : (
            notEditableText("Full name:", modalData.name)
          )}
          {isEdit ? (
            <InputStyled
              title="Email:"
              value={modalData.email}
              onChange={(value) => {
                setModalData((prev: any) => ({ ...prev, ["email"]: value }));
              }}
            />
          ) : (
            notEditableText("Email:", modalData.email)
          )}
          {isEdit ? (
            <InputStyled
              title="Natural operator id:"
              value={modalData.natural_operator_id}
              onChange={(value) => {
                setModalData((prev: any) => ({
                  ...prev,
                  ["natural_operator_id"]: value,
                }));
              }}
            />
          ) : (
            notEditableText(
              "Natural operator id:",
              modalData.natural_operator_id
            )
          )}
          {isEdit ? (
            <DropdownStyled
              onChange={(value) => {
                setModalData((prev: any) => ({ ...prev, ["role"]: value }));
              }}
              defaultValue={modalData.role as string}
              title="Role"
              options={[
                {
                  value: "operator",
                  label: "Operator",
                },
                {
                  value: "admin",
                  label: "Admin",
                },
              ]}
            />
          ) : (
            notEditableText("Role:", modalData.role)
          )}

          {isEdit ? (
            <DropdownStyled
              onChange={(value) => {
                setModalData((prev: any) => ({
                  ...prev,
                  ["is_active"]: value === "active",
                }));
              }}
              defaultValue={modalData.is_active ? "Active" : "Not Active"}
              title="Status"
              options={[
                {
                  value: "active",
                  label: "Active",
                },
                {
                  value: "not_active",
                  label: "Not active",
                },
              ]}
            />
          ) : (
            notEditableText(
              "Status:",
              modalData.is_active ? "Active" : "Not Active"
            )
          )}
          {isEdit ? (
            <InputStyled
              title="Password:"
              value={modalData.password}
              onChange={(value) => {
                setModalData((prev: any) => ({ ...prev, ["password"]: value }));
              }}
            />
          ) : (
            <span style={{ height: "66px" }}></span>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            {isEdit && (
              <ButtonStyled
                background={Colors.WHITE_COLOR}
                onClick={() => {
                  setModalData(data);
                  setIsEdit(false);
                }}
              >
                <TextStyled style={{ color: Colors.BLACK_COLOR }}>
                  Cancel
                </TextStyled>
              </ButtonStyled>
            )}
            <ButtonStyled
              onClick={() =>
                isEdit ? onUserUpdate(modalData) : setIsEdit(() => true)
              }
            >
              <TextStyled>{isEdit ? "Save" : "Edit"}</TextStyled>
            </ButtonStyled>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserScreen;
