export enum Colors {
  WHITE_COLOR = "#FFFFFF",
  BLACK_COLOR = "#1E1E1E",
  BUTTON_COLOR = "linear-gradient(127.87deg, #37B2B9 30.17%, #69C28E 96.65%), linear-gradient(90.97deg, #592C7D 0.16%, #3FBDD4 43.58%, #6BC495 101.83%)",
  BUTTON_RED_COLOR = "linear-gradient(107.2deg,#960f0f 10.6%,#f70000 91.1%)",
  MENU_COLOR = "linear-gradient(90.97deg, #592C7D 0.16%, #3FBDD4 43.58%, #6BC495 101.83%)",
  SHADOW_COLOR = "rgba(0, 0, 0, 0.05)",
  ACTIVE_COLOR = "#D6FFE9",
  NON_ACTIVE_COLOR = "#FFCFCF",
  AVATAR_COLOR = "#59C1B0",
  TITLE_SILVER_COLOR = "#616F81",
  TITLE_INNER_COLOR = "#ACB9C9",
  TITLE_BLACK_MUTED = "#2F363E",
  TITLE_MUTED = "#858691",
  TITLE_ACTIVE = "#359A73",
  TITLE_NON_ACTIVE = "#CB1414",
  BLACK = "#000000",
  SILVER_COLOR = "#696f8c",
  SILVER_BLUR_COLOR = "#292d32",
  ROW_COLOR = "#282828",
  SCROLL_COLOR = "#d9f0e3",
  MAIN_BLUE = "#3fbdd4",
}
