import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UpdateClient } from "../../../../api/ClientApi/Client";
import { Link } from "../../../../assets/Link";
import { Colors } from "../../../../utils/colors";
import ButtonStyled from "../../../Button";
import ModalStyled from "../../../Modal";
import { Cansel } from "../Licensing.styled";

import {
  CancelStyled,
  Content,
  ModalContent,
  Privacy,
  Title,
  Updated,
} from "./LicenseModal.styled";

type TableStyledProps = {
  title?: string;
  children?: JSX.Element;
  handleSave?: () => void;
  initialData?: UpdateClient;
  width?: number;
  okText?: string;
  footer?: null;
};
const License: FC<TableStyledProps> = ({
  children,
  title,
  width,
  okText,
  footer,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{ width: "min-content" }}
        onClick={() => setIsModalOpen(true)}
      >
        {children}
      </div>
      <ModalStyled
        footer={footer}
        okText={okText}
        width={width}
        title={title}
        isModalOpen={isModalOpen}
        modalOpen={setIsModalOpen}
      >
        <ModalContent>
          <Privacy>
            <Title>Privacy policy</Title>
            <div
              onClick={() => navigate("/privacy")}
              style={{ display: "flex", cursor: "pointer" }}
            >
              <Link />
            </div>
          </Privacy>
          <Updated>Updated December 05, 2022</Updated>
          <Content>
            Dribbble Holdings Limited (the “Dribbble Group”) is comprised of
            several companies, which together provide tools to help the world’s
            designers to create, develop and promote their talents (each a
            “Service” and collectively, the “Services”). The companies within
            the Dribbble Group each act as the data controller for personal data
            processed in respect of their Services (each a “Group Company” and
            together the “Group Companies”) and referred to as “our,” “we,” or
            “us” below. Our Group Companies operate the Services, which include
            websites, software, mobile services, and applications. This page
            explains the terms by which you may use our online and/or mobile
            services, website, and software provided on or in connection with
            the Services. These terms apply to all sites to which link to these
            terms, including, but not limited to, dribbble.com,
            creativemarket.com, fontspring.com, fontsquirrel.com, and any
            subdomains thereof. By using the Services, creating an account and
            checking the "I agree" (or similar) box, or otherwise accessing or
            using the Services, you (1) agree that you have read, understood,
            and agree to be bound by the terms and conditions of these Terms of
            Service (these “Terms”), and (2) also that you have read and
            acknowledge the collection and use of your information as set forth
            in our privacy policy (the "Privacy Policy"), whether or not you are
            a registered user of the Services. Your use of certain Services or
            features of our Services may be subject to additional terms. For
            example, if you purchase a font or other Asset from Creative Market,
            purchase a font from Fontspring, or download a font from Font
            Squirrel, additional license terms for the font or Asset may apply
            (each, a “License”). Similarly, if you make a purchase through the
            Services, an applicable return policy may apply. If you sign up to
            be a seller or affiliate (e.g., on Shop and Partner terms on
            Creative Market or Foundry terms on Fontspring), your use of the
            Services in such a capacity may be subject to additional terms. If
            you engage in sharing content and interact with other users in our
            community (e.g., posting or commenting on content at Dribbble), your
            conduct may be subject to Community Guidelines. We may offer
            promotions or contests through the Services from time to time, and
            your participation in these may also be subject to additional terms.
            To the extent additional terms affect your use of the Service, those
            terms (including, but not limited to, the licenses and Privacy
            Policy noted above) are hereby incorporated by reference into these
            Terms.
          </Content>
          <CancelStyled>
            <ButtonStyled
              background={Colors.WHITE_COLOR}
              padding="8px 16px"
              onClick={() => setIsModalOpen(false)}
            >
              <Cansel>Cancel</Cansel>
            </ButtonStyled>
          </CancelStyled>
        </ModalContent>
      </ModalStyled>
    </>
  );
};

export default License;
