import type { MenuProps } from "antd";
import { Layout } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Licensing,
  Logo,
  MenuArrow,
  Report,
  Setting,
  SignOut,
  User,
} from "../../assets";
import { useUser } from "../../context/auth-context";
import { Colors } from "../../utils/colors";

import {
  AdminEmail,
  AdminName,
  LogoWrapper,
  MenuStyled,
  RowStyled,
  SiderStyled,
  StyledUserRow,
  UserNameStyled,
} from "./Layout.styled";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

type MainLayoutProps = {
  children: JSX.Element;
  active: string;
  onChange?: (value: string) => void;
};
const MainLayout: FC<MainLayoutProps> = ({ children, onChange, active }) => {
  const { user, onUpdate, onClear } = useUser();
  const [activeKey, setActiveKey] = useState(active);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string[] | undefined>();

  const isAuth =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  useEffect(() => {
    isAuth && onUpdate();
  }, [isAuth]);

  useEffect(() => {
    if (activeKey === "0" || activeKey === "1" || activeKey === "2") {
      setActiveTab(["0"]);
    }
    if (activeKey === "3" || activeKey === "4" || activeKey === "5") {
      setActiveTab(["3"]);
    }
  }, [activeKey]);

  useEffect(() => {
    switch (window.location.pathname) {
      case "/":
        return setActiveKey("1");
      case "/clients-management":
        return setActiveKey("2");
      case "/list-reports":
        return setActiveKey("4");
      case "/graphical-reports":
        return setActiveKey("5");
      case "/settings":
        return setActiveKey("6");
      case "/licensing":
        return setActiveKey("7");
      default:
        return setActiveKey("1");
    }
  }, [window]);

  const navigateTo = (value: string) => {
    switch (value) {
      case "1":
        return navigate("/");
      case "2":
        return navigate("/clients-management");
      case "4":
        return navigate("/list-reports");
      case "5":
        return navigate("/graphical-reports");
      case "6":
        return navigate("/settings");
      case "7":
        return navigate("/licensing");
    }
    return navigate("/");
  };

  const items: MenuItem[] = [
    getItem(
      "Management",
      "0",
      <User
        stroke={
          activeKey === "1" || activeKey === "2"
            ? Colors.WHITE_COLOR
            : Colors.TITLE_MUTED
        }
      />,
      [getItem("Users Management", "1"), getItem("Clients management", "2")]
    ),
    getItem(
      "Reports",
      "3",
      <Report
        stroke={
          activeKey === "4" || activeKey === "5"
            ? Colors.WHITE_COLOR
            : Colors.TITLE_MUTED
        }
      />,
      [getItem("Calls", "4"), getItem("Graphical report", "5")]
    ),
    getItem(
      "Settings",
      "6",
      <Setting
        fill={activeKey === "6" ? Colors.WHITE_COLOR : Colors.TITLE_MUTED}
      />
    ),
    getItem(
      "Licensing",
      "7",
      <Licensing
        stroke={activeKey === "7" ? Colors.WHITE_COLOR : Colors.TITLE_MUTED}
      />
    ),
  ];

  // const updateUser = useMutation(userApi.update);
  const signOut = () => {
    localStorage.removeItem("token");
    onClear();
    navigate("/login");
    //TODO update dt_token_expire for current date

    // updateUser.mutate(
    //   {
    //     dt_token_expire: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSSSZ"),
    //     id: user.operator_id,
    //   },
    //   {
    //     onSuccess: () => {
    //       NotificationManager.success("User Logout");
    //       localStorage.removeItem("token");
    //     },
    //     onError: (error: any) => {
    //       NotificationManager.error(
    //         `${JSON.stringify(error?.request.response)}`
    //       );
    //     },
    //   }
    // );
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SiderStyled width={268}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <MenuStyled
              openKeys={activeTab}
              onOpenChange={(val) => setActiveTab(val)}
              selectedKeys={[activeKey]}
              onSelect={(value) => {
                onChange?.(value.key);
                navigateTo(value.key);
                setActiveKey(value.key);
              }}
              expandIcon={<MenuArrow />}
              defaultSelectedKeys={[activeKey]}
              mode="inline"
              items={items}
            />
          </div>
          {user && (
            <div>
              <StyledUserRow>
                <UserNameStyled>{`${user?.name?.split(" ")?.[0]?.charAt(0)}${
                  user?.name?.split(" ")?.[1]?.charAt(0) || ""
                }`}</UserNameStyled>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    columnGap: "4px",
                    marginLeft: "8px",
                    justifyContent: "space-between",
                  }}
                >
                  <AdminName>{user.name}</AdminName>
                  <AdminEmail>{user.email}</AdminEmail>
                </div>
              </StyledUserRow>
              <RowStyled onClick={signOut}>
                <SignOut />
                <span>Exit</span>
              </RowStyled>
            </div>
          )}
        </div>
      </SiderStyled>
      <Layout className="site-layout">{children}</Layout>
    </Layout>
  );
};

export default MainLayout;
