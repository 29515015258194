import AxiosLib from "axios";

const host =
  process.env.REACT_APP_API_URL || "https://api.admin.corx.corsound.ai";

const baseURL = `${host}/`;

export const axios = AxiosLib.create({ baseURL });

axios.interceptors.request.use(async (req) => {
  req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return req;
});

axios.interceptors.response.use(
  (response) => {
    if (response?.headers["content-type"] === "text/csv; charset=utf-8") {
      return response;
    }
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      sessionStorage.removeItem("token");
      window.location.replace("/login");
    }
    throw error.response.data;
  }
);
