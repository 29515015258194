export const baseURL = process.env["API_URL"];

export const userEndpoints = {
  login: "/operator/login",
  operator: "/operator",
  profile: "/operator/profile",
};

export const clientEndpoints = {
  client: "/client",
  login: "/operator/login",
  signature: "/signature",
};

export const pieEndpoints = {
  verify: "chart/verify/pie",
  gender: "chart/gender/pie",
  age: "chart/age/pie",
  ethnicity: "chart/ethnicity/pie",
};

export const barEndpoints = {
  verify: "chart/verify/bar",
  gender: "chart/gender/bar",
  age: "chart/age/bar",
  ethnicity: "chart/ethnicity/bar",
};

export const callEndpoints = {
  call: "call",
};

export const licenseEndpoint = {
  license: "license",
  licenseLast: "license/last",
};

export const configEndpoint = {
  config: "config",
};
