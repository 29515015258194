import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { FC, useState } from "react";

import { UserProps } from "../../../../api/UserApi/User";
import InputStyled from "../../../Input/Input";
import { Title } from "../../../Input/Input/Input.styled";
import ModalStyled from "../../../Modal";
import { SettingContent } from "../../Settings/Settings.styled";

import { ModalContent } from "./AccountModal.styled";

type TableStyledProps = {
  title: string;
  onClick?: () => void;
  children?: JSX.Element;
  handleSave?: (data: any) => void;
  initialData?: UserProps;
  width?: number;
  isSuccess?: boolean;
};
const AccountModal: FC<TableStyledProps> = ({
  children,
  title,
  handleSave,
  initialData = {
    dt_create: "",
    dt_last_login: "",
    dt_token_expire: "",
    dt_update: "",
    id: "",
    operator_id: "",
    org_id: 0,
    role: "",
    name: "",
    email: "",
    password: "",
    natural_operator_id: "",
    is_active: true,
    operator_phone: "",
  },
  width,
  isSuccess,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [modalData, setData] = useState<UserProps>(initialData);

  return (
    <>
      <div
        style={{ width: "min-content" }}
        onClick={() => setIsModalOpen(true)}
      >
        {children}
      </div>
      <ModalStyled
        width={width}
        handleSave={() => {
          handleSave?.(modalData);
          isSuccess &&
            setData({
              dt_create: "",
              dt_last_login: "",
              dt_token_expire: "",
              dt_update: "",
              id: "",
              operator_id: "",
              org_id: 0,
              role: "",
              name: "",
              email: "",
              password: "",
              natural_operator_id: "",
              is_active: true,
              operator_phone: "",
            });
          isSuccess && setData(initialData);
        }}
        title={title}
        isModalOpen={isModalOpen}
        modalOpen={setIsModalOpen}
      >
        <ModalContent>
          <Title></Title>
          <InputStyled
            title="Full name:"
            value={modalData.name}
            onChange={(value) => {
              setData((prev: any) => ({ ...prev, ["name"]: value }));
            }}
          />
          <InputStyled
            title="Email:"
            value={modalData.email}
            onChange={(value) => {
              setData((prev: any) => ({ ...prev, ["email"]: value }));
            }}
          />
          <InputStyled
            type="password"
            title="Password:"
            value={modalData.password}
            onChange={(value) => {
              setData((prev: any) => ({ ...prev, ["password"]: value }));
            }}
          />
          <InputStyled
            title="Natural operator id:"
            value={modalData.natural_operator_id}
            onChange={(value) => {
              setData((prev: any) => ({
                ...prev,
                ["natural_operator_id"]: value,
              }));
            }}
          />
          <InputStyled
            type="number"
            title="Phone:"
            value={modalData.operator_phone}
            onChange={(value) => {
              setData((prev: any) => ({
                ...prev,
                ["operator_phone"]: value,
              }));
            }}
          />
          <Checkbox
            checked={modalData.role === "admin"}
            onChange={(e: CheckboxChangeEvent) => {
              const checked = e.target.checked;
              setData((prev: any) => ({
                ...prev,
                ["role"]: checked ? "admin" : "operator",
              }));
            }}
          >
            <SettingContent>Admin</SettingContent>
          </Checkbox>

          {/*<DropdownStyled*/}
          {/*  onChange={(value) => {*/}
          {/*    setData((prev: any) => ({ ...prev, ["is_active"]: value === "active" }));*/}
          {/*    setModalData((prev: any) => ({ ...prev, ["is_active"]: value === "active" }));*/}
          {/*  }}*/}
          {/*  defaultValue={modalData.is_active}*/}
          {/*  title="is_active"*/}
          {/*  options={[*/}
          {/*    {*/}
          {/*      value: "active",*/}
          {/*      label: "Active",*/}
          {/*    },*/}
          {/*    {*/}
          {/*      value: "not_active",*/}
          {/*      label: "Not active",*/}
          {/*    },*/}
          {/*  ]}*/}
          {/*/>*/}
        </ModalContent>
      </ModalStyled>
    </>
  );
};

export default AccountModal;
