import React, { FC, useState } from "react";
import { useQuery } from "react-query";

import { licenseApi, LicenseType } from "../../../api/LicenseApi/License";
import { useUser } from "../../../context/auth-context";
import { Colors } from "../../../utils/colors";
import { QueryNames } from "../../../utils/constants/QueryNames";
import ButtonStyled from "../../Button";
import PanelHeader from "../../PanelHeader";

import AccountModal from "./LicenseModal/LicenseModal";
import {
  ButtonText,
  ButtonWrapper,
  Cansel,
  ContentStyled,
  DateStyled,
  End,
  FooterStyled,
  HeadTitle,
  LicenseContent,
  PlanWrapper,
  Price,
  Privacy,
  PurchasedLicense,
  StyledPopover,
  Subscription,
  Text,
  TextWrapper,
} from "./Licensing.styled";
import License from "./PrivacyModal/LicenseModal";

const LicensingScreen: FC = () => {
  const { data: licenses } = useQuery({
    queryKey: [QueryNames.GET_LICENSE],
    queryFn: () => {
      return licenseApi.getLicenses();
    },
  });

  const { data: licensesLast } = useQuery({
    queryKey: [QueryNames.GET_LICENSE_LAST],
    queryFn: () => {
      return licenseApi.getLicensesLast();
    },
  });

  const { user } = useUser();

  // const isAuth =
  //   localStorage.getItem("token") || sessionStorage.getItem("token");

  const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   setIsModalOpen(true);
  // }, [isAuth]);

  const checkExpireDate = (expire: string) => {
    const currentDate = new Date();
    const expireDate = new Date(expire);

    return currentDate.getTime() > expireDate.getTime();
  };

  const licensesBlock = (licenses: LicenseType[]) => {
    return licenses?.map((license) => {
      return (
        <PurchasedLicense key={license.license_type}>
          <HeadTitle>
            <Subscription>Purchased license</Subscription>
            <Subscription>{license.license_price}</Subscription>
            <Price>{license.license_type}</Price>
          </HeadTitle>

          <LicenseContent>
            <TextWrapper style={{ marginTop: "5px" }}>
              <DateStyled>Date of updating license:</DateStyled>
              <End>{license.dt_month}</End>
            </TextWrapper>
            <TextWrapper>
              <Text>{`Limit operators: ${license.limit_operators}`}</Text>
            </TextWrapper>
            <TextWrapper>
              <Text>{`Total operators: ${license.total_operators}`}</Text>
            </TextWrapper>
            <TextWrapper>
              <Text>{`Total calls: ${license.count_calls}`}</Text>
            </TextWrapper>
            <TextWrapper>
              <Text>{`Total enrolls: ${license.count_enrolls}`}</Text>
            </TextWrapper>
            <TextWrapper>
              <Text>{`Total of verifies: ${license.count_verifies}`}</Text>
            </TextWrapper>
            <TextWrapper>
              <Text>{`Call price: ${license.price_call}`}</Text>
            </TextWrapper>
            <TextWrapper>
              <Text>{`Enroll price: ${license.price_enroll}`}</Text>
            </TextWrapper>
            <TextWrapper>
              <Text>{`Verify price: ${license.price_verify}`}</Text>
            </TextWrapper>
          </LicenseContent>
          <ButtonWrapper>
            <StyledPopover title="Upgrade plan" userData={user} />
            <ButtonStyled background={Colors.WHITE_COLOR} padding="8px 16px">
              <Cansel>Cancel subscription</Cansel>
            </ButtonStyled>
          </ButtonWrapper>
        </PurchasedLicense>
      );
    });
  };
  return (
    <>
      <PanelHeader
        tabTitle="Licensing"
        title="User management"
        subTitle={`Hello ${
          user?.name?.split(" ")?.[0] || ""
        }, we show you your most recent activity`}
      />
      <ContentStyled>
        {licensesLast && checkExpireDate(licensesLast.last) && (
          <div style={{ marginBottom: "16px" }}>
            <ButtonStyled
              onClick={() => setIsModalOpen(true)}
              padding="8px 16px"
              border="none"
            >
              <ButtonText>Your subscription expires this month</ButtonText>
            </ButtonStyled>
          </div>
        )}
        <Subscription>Subscription plan</Subscription>
        <PlanWrapper>{licensesBlock(licenses)}</PlanWrapper>
        <AccountModal
          userData={user}
          width={424}
          footer={null}
          modalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      </ContentStyled>
      <FooterStyled>
        <License footer={null} width={742}>
          <Privacy>Privacy policy</Privacy>
        </License>
        <Privacy>Data protection agreement</Privacy>
      </FooterStyled>
    </>
  );
};

export default LicensingScreen;
