import styled from "styled-components";

import { Colors } from "../../../../utils/colors";

export const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Content = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.BLACK};
`;

export const DeleteStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & span {
    white-space: nowrap;
    font-size: 12px;
    color: ${Colors.WHITE_COLOR};
  }
`;

export const AccountWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const DeleteWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
