import React, { createContext, useContext, useEffect, useState } from "react";

import { userApi, UserProps } from "../api/UserApi/User";

interface User {
  user: UserProps;
  onUpdate: () => void;
  onClear: () => void;
}

const UserContext = createContext<User>({
  user: {} as UserProps,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onUpdate: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClear: () => {},
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const UserProvider = ({ children }) => {
  const [user, setUser] = useState<UserProps | null>(null);

  const isAuth =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  const get = async () => {
    const user = await userApi.getProfile();
    setUser(user);
  };

  useEffect(() => {
    if (isAuth) {
      get().then();
    }
  }, [isAuth]);

  const onUpdate = async (): Promise<void> => {
    get().then();
  };

  const onClear = async (): Promise<void> => {
    setUser(null);
  };

  return (
    <UserContext.Provider
      value={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        user,
        onUpdate,
        onClear,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): User => useContext(UserContext);

export default UserProvider;
