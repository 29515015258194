import { Radio, Switch } from "antd";
import styled from "styled-components";

import { Colors } from "../../../utils/colors";

type MarginTop = { margin?: string };

export const ContentWrapper = styled.div`
  background: ${Colors.WHITE_COLOR};
  padding: 30px 0 30px 30px;
  margin: 24px;
  color: ${Colors.BLACK_COLOR};
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 0 12px 12px 12px;
`;

export const SettingTitle = styled.div<MarginTop>`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin: ${({ margin }) => margin || "0"};
  color: ${Colors.TITLE_BLACK_MUTED};
`;

export const RadioStyled = styled(Radio)<MarginTop>`
  margin: ${({ margin }) => margin || "0"};
  .ant-radio {
    margin-right: 4px;
  }
`;

export const SwitchStyled = styled(Switch)`
  width: 42px;
  height: 24px;

  .ant-switch-handle {
    top: 3px;
  }
`;
export const SettingContent = styled.div<MarginTop>`
  margin: ${({ margin }) => margin || "0"};
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  color: ${Colors.TITLE_BLACK_MUTED};
`;

export const SettingSubContent = styled.div<MarginTop>`
  margin: ${({ margin }) => margin || "0"};
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  color: #2f363e;
`;

export const SubText = styled.div<MarginTop>`
  margin: ${({ margin }) => margin || "0"};
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(47, 54, 62, 0.5);
`;

export const Hr = styled.div<MarginTop>`
  margin: ${({ margin }) => margin || "24px 0"};
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const SettingContainer = styled.div<MarginTop & { gap?: string }>`
  max-width: 596px;
  margin: ${({ margin }) => margin || "12px 0 0 0"};
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || "12px"};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const HashTag = styled.div<{ active: boolean }>`
  background: ${({ active }) => (active ? "#00af53" : "#f5f5f5")};

  padding: 4px 8px;
  width: max-content;
  border-radius: 35px;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
`;

export const HashTagWrapper = styled.div`
  margin-left: 32px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;
