import { Layout, Menu, Row } from "antd";
import styled from "styled-components";

import { Colors } from "../../utils/colors";

export const SiderStyled = styled(Layout.Sider)`
  background-color: ${Colors.BLACK_COLOR}!important;
`;

export const MenuStyled = styled(Menu)`
  background-color: ${Colors.BLACK_COLOR};
  padding-right: 24px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  & .ant-menu-submenu-open {
    & svg:last-child {
      transform: translateY(10%) rotate(180deg);
    }
  }
  & .ant-menu-submenu-selected .ant-menu-submenu-selected {
    color: ${Colors.WHITE_COLOR};
  }

  & .ant-menu-item-selected {
    background: ${Colors.MENU_COLOR};
    border-radius: 0 8px 8px 0;

    & span {
      color: ${Colors.WHITE_COLOR};
    }
  }

  & .ant-menu-submenu-title {
    margin: 0;
    padding-right: 15px;
  }

  & .ant-menu-item {
    padding: 6px 0;
    width: 100%;
    line-height: 20px;
    height: max-content;
    margin: 20px 24px 0 0;

    & svg:first-child {
      margin-right: 16px;
    }
  }

  & .ant-menu-submenu-title {
    width: 100%;

    & svg:first-child {
      margin-right: 16px;
    }
  }

  & .ant-menu-submenu {
    margin-bottom: 28px;
  }

  & .ant-menu-submenu-selected {
    & div {
      transition: none;
      border-radius: 0 8px 8px 0;
      background: ${Colors.MENU_COLOR};
    }

    & div:active {
      background-color: initial;
    }

    & .ant-menu-item-selected {
      background: initial;
    }

    & div span {
      color: ${Colors.WHITE_COLOR};
    }

    & svg path:last-child {
      fill: ${Colors.WHITE_COLOR};
    }
  }

  & > li:last-child {
    margin-top: 40px;
  }

  & .ant-menu-title-content {
    color: ${Colors.TITLE_MUTED};
  }
`;

export const LogoWrapper = styled.div`
  padding: 24px 24px 62px 24px;
`;

export const UserNameStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 38px;
  height: 38px;

  background: ${Colors.AVATAR_COLOR};
  border-radius: 8px;

  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.14px;

  color: ${Colors.WHITE_COLOR};
`;

export const StyledUserRow = styled(Row)`
  padding: 8px;
  margin: 0 24px;
  background: ${Colors.ROW_COLOR};
  border-radius: 8px;
`;

export const AdminName = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${Colors.WHITE_COLOR};

  width: 156px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
`;

export const AdminEmail = styled.div`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${Colors.TITLE_MUTED};

  width: 156px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
`;

export const RowStyled = styled(Row)`
  width: max-content;
  margin: 34px 24px 26px 24px;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }

  & span {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${Colors.WHITE_COLOR};
  }
`;
