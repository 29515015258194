import React, { FC, useEffect, useState } from "react";
import { NotificationContainer } from "react-notifications";
import { Navigate } from "react-router-dom";

import MainLayout from "../../components/Layout";
import AgentsManagementScreen from "../../components/screens/AgentsManagement/AgentsManagementScreen";
import ClientsManagementScreen from "../../components/screens/CLientsManagement/ClientsManagementScreen";
import GraphicalReportsScreen from "../../components/screens/GraphicalReports/GraphicalReportsScreen";
import LicensingScreen from "../../components/screens/Licensing/LicensingScreen";
import ListReportsScreen from "../../components/screens/ListReporst/ListReportsScreen";
import PrivacyScreen from "../../components/screens/Privacy/PrivacyScreen";
import SettingsScreen from "../../components/screens/Settings/SettingsScreen";

const Home: FC = ({}) => {
  const [active, setActiveKey] = useState("1");
  const isAuth =
    localStorage.getItem("token") || sessionStorage.getItem("token");

  useEffect(() => {
    switch (window.location.pathname) {
      case "/":
        return setActiveKey("1");
      case "/clients-management":
        return setActiveKey("2");
      case "/list-reports":
        return setActiveKey("4");
      case "/graphical-reports":
        return setActiveKey("5");
      case "/settings":
        return setActiveKey("6");
      case "/licensing":
        return setActiveKey("7");
      default:
        return setActiveKey("1");
    }
  }, [window]);

  return (
    <>
      {!isAuth && <Navigate to={"/login"} replace={true} />}
      <NotificationContainer />
      <MainLayout onChange={setActiveKey} active={active}>
        <>
          {active === "1" ? <AgentsManagementScreen /> : <></>}
          {active === "2" ? <ClientsManagementScreen /> : <></>}
          {active === "3" ? <PrivacyScreen /> : <></>}
          {active === "4" ? <ListReportsScreen /> : <></>}
          {active === "5" ? <GraphicalReportsScreen /> : <></>}
          {active === "6" ? <SettingsScreen /> : <></>}
          {active === "7" ? <LicensingScreen /> : <></>}
        </>
      </MainLayout>
    </>
  );
};

export default Home;
